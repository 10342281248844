import React from 'react';
import styled from '@emotion/styled';
import { Link as RouterLink, graphql } from 'gatsby';
import { Typography, Link, Box } from '@mui/material';
import Layout from '../layout';
import CenteredContainer from '../components/CenteredContainer';
import FaqAccordion from '../components/FaqAccordion';

const FAQPage = ({ data }) => {
  const { naslov, podnaslov, faq, ikona } = data.strapiCestaPitanja;
  return (
    <Layout offset={1}>
      <FaqWrapper>
        <CenteredContainer maxWidth={720}>
          <img src={ikona.localFile.publicURL} alt="" />
          <Box my={2} textAlign="center">
            <Typography variant="h2" gutterBottom>
              {naslov}
            </Typography>
          </Box>
          <Typography variant="body1" gutterBottom>
            {podnaslov}
          </Typography>
          <Box mt={3}>
            {faq.map(({ id, pitanje, odgovor }) => (
              <FaqAccordion key={id} question={pitanje} answer={odgovor} />
            ))}
          </Box>
          <Box mt={2} width="100%">
            <Typography variant="body1">
              Imate dodatna pitanja?{' '}
              <Link to="/kontaktirajte-nas" component={RouterLink}>
                Kontaktirajte nas
              </Link>
            </Typography>
          </Box>
        </CenteredContainer>
      </FaqWrapper>
    </Layout>
  );
};

const FaqWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.light};
  display: flex;
  flex: auto;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  margin-top: -40px;
  padding-top: 40px;
`;

export const query = graphql`
  query FaqQuery {
    strapiCestaPitanja {
      naslov
      podnaslov
      ikona {
        localFile {
          publicURL
        }
      }
      faq {
        id
        odgovor
        pitanje
      }
    }
  }
`;

export default FAQPage;
